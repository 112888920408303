/* eslint-disable prettier/prettier */
import React from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// @material-ui/icons
// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import Info from "components/Typography/Info.js";
import Success from "components/Typography/Success.js";
import Danger from "components/Typography/Danger.js";
import Warning from "components/Typography/Warning.js";
import Primary from "components/Typography/Primary.js";
import Muted from "components/Typography/Muted.js";

import blog6 from "assets/img/solafide.png";
import blog8 from "assets/img/dc7notes.png";
import blog1 from "assets/img/wpft.png";
import blog2 from "assets/img/reclique.png";
import blog9 from "assets/img/jg.png";

import Particles from "components/Particles.js";

import nirvana from "assets/img/nirvana.png";
import bape from "assets/img/bape.png";
import topdown from "assets/img/topdown.png";

import sectionSimilarStoriesStyle from "assets/jss/material-kit-pro-react/views/blogPostSections/sectionSimilarStoriesStyle.js";

const useStyles = makeStyles(sectionSimilarStoriesStyle);

export default function SectionSimilarStories() {
  const classes = useStyles();
  return (
    <div className={classes.section}>
      <Particles />

      <div className={classes.container}>
        <GridContainer>
          <GridItem md={12}>
            <h2 className={classes.title + " " + classes.textCenter}>
              PORTFOLIO WEBSITES
            </h2>
            <br />
            <GridContainer>
              <GridItem xs={12} sm={6} md={6}>
                <Card blog>
                  <CardHeader image>
                    <a href="https://solafide-services.com">
                      <img src={blog6} alt="..." />
                    </a>
                    <div
                      className={classes.coloredShadow}
                      style={{
                        backgroundImage: "url(" + blog6 + ")",
                        opacity: "1",
                      }}
                    />
                  </CardHeader>
                  <CardBody>
                    <Info>
                      <h6>SOLAFIDE-SERVICES</h6>
                    </Info>
                    <h4 className={classes.cardTitle}>
                      <a href="https://solafide-services.com">
                        solafide-services.com
                      </a>
                    </h4>
                    <p className={classes.description}>
                      Modern website built with React.js showcasing beautiful animations and seamless navigation desktop or mobile.
                    </p>
                  </CardBody>
                </Card>
              </GridItem>
              <GridItem xs={12} sm={6} md={6}>
                <Card blog>
                  <CardHeader image>
                    <a href="https://wichpressfoodtruck.com">
                      <img src={blog1} alt="..." />
                    </a>
                    <div
                      className={classes.coloredShadow}
                      style={{
                        backgroundImage: "url(" + blog1 + ")",
                        opacity: "1",
                      }}
                    />
                  </CardHeader>
                  <CardBody>
                    <Success>
                      <h6>WichPress Food Truck</h6>
                    </Success>
                    <h4 className={classes.cardTitle}>
                      <a href="https://wichpressfoodtruck.com">
                        WichPress Food Truck
                      </a>
                    </h4>
                    <p className={classes.description}>This food truck website follows simple design and is SEO optimized.</p>
                  </CardBody>
                </Card>
              </GridItem>
            </GridContainer>
            <GridContainer>
            <GridItem xs={12} sm={4} md={4}>
                <Card blog>
                  <CardHeader image>
                    <a href="https://lissettpacheco.info">
                      <img src={blog8} alt="..." />
                    </a>
                    <div
                      className={classes.coloredShadow}
                      style={{
                        backgroundImage: "url(" + blog8 + ")",
                        opacity: "1",
                      }}
                    />
                  </CardHeader>
                  <CardBody>
                    <Primary>
                      <h6>Lissett Pacheco Notarial Services</h6>
                    </Primary>
                    <h4 className={classes.cardTitle}>
                      <a href="https://lissettpacheco.info">LP Services</a>
                    </h4>
                    <p className={classes.description}>
                      Sales website.
                    </p>
                  </CardBody>
                </Card>
              </GridItem>
            <GridItem xs={12} sm={4} md={4}>
            <Card blog>
              <CardHeader image>
                <a href="http://dc7travel.surge.sh">
                  <img src={blog2} alt="..." />
                </a>
                <div
                  className={classes.coloredShadow}
                  style={{
                    backgroundImage: "url(" + blog2 + ")",
                    opacity: "1",
                  }}
                />
              </CardHeader>
              <CardBody>
                <Danger>
                  <h6>Travel Logging Site</h6>
                </Danger>
                <h4 className={classes.cardTitle}>
                  <a href="http://dc7travel.surge.sh">DC7 Travel Log</a>
                </h4>
                <p className={classes.description}>
                  MERN stack - stores trip logs for different users and displays main page with their travels.
                </p>
              </CardBody>
            </Card>
          </GridItem>
       
             
            
             
              <GridItem xs={12} sm={4} md={4}>
                <Card blog>
                  <CardHeader image>
                    <a href="https://bandananirvana.com">
                      <img src={nirvana} alt="..." />
                    </a>
                    <div
                      className={classes.coloredShadow}
                      style={{
                        backgroundImage: "url(" + nirvana + ")",
                        opacity: "1",
                      }}
                    />
                  </CardHeader>
                  <CardBody>
                    <Muted>
                      <h6>BANDANA NIRVANA</h6>
                    </Muted>
                    <h4 className={classes.cardTitle}>
                      <a href="https://bandananirvana.com">Bandana Nirvana</a>
                    </h4>
                    <p className={classes.description}>
                      Page showing authentication, storage, and page structure -- 
                      utilizes Firebase and Stripe payments implemented. This page also functions in dark mode.
                    
                    </p>
                  </CardBody>
                </Card>
              </GridItem>
        
            </GridContainer>
          </GridItem>

      
              <GridItem xs={12} sm={3} md={3}>
            <Card blog>
              <CardHeader image>
                <a href="https://topdownrv.netlify.app">
                  <img src={topdown} alt="..." />
                </a>
                <div
                  className={classes.coloredShadow}
                  style={{
                    backgroundImage: "url(" + topdown + ")",
                    opacity: "1",
                  }}
                />
              </CardHeader>
              <CardBody>
                <Danger>
                  <h6>
                    Top-Down RV
                  </h6>
                </Danger>
                <h4 className={classes.cardTitle}>
                  <a href="https://topdownrv.netlify.app">Top-Down RV</a>
                </h4>
                <p className={classes.description}>Sample site</p>
              </CardBody>
            </Card>
          </GridItem>
          <GridItem xs={12} sm={3} md={3}>
                <Card blog>
                  <CardHeader image>
                    <a href="https://juliusgomes.netlify.app">
                      <img src={blog9} alt="..." />
                    </a>
                    <div
                      className={classes.coloredShadow}
                      style={{
                        backgroundImage: "url(" + blog9 + ")",
                        opacity: "1",
                      }}
                    />
                  </CardHeader>
                  <CardBody>
                    <Warning>
                      <h6>Julius Gomes</h6>
                    </Warning>
                    <h4 className={classes.cardTitle}>
                      <a href="https://juliusgomes.netlify.app">
                        Julius Gomes Resume Page
                      </a>
                    </h4>
                    <p className={classes.description}>
                      Resume page built with React.js
                    </p>
                  </CardBody>
                </Card>
              </GridItem>

       
          <GridItem xs={12} sm={3} md={3}>
            <Card blog>
              <CardHeader image>
                <a href="https://hackademy-30.gitlab.io/directory-donato-carrassi/">
                  <img src={bape} alt="..." />
                </a>
                <div
                  className={classes.coloredShadow}
                  style={{
                    backgroundImage: "url(" + bape + ")",
                    opacity: "1",
                  }}
                />
              </CardHeader>
              <CardBody>
                <Danger>
                  <h6>
                     Phone directory app (vanilla js)
                  </h6>
                </Danger>
                <h4 className={classes.cardTitle}>
                  <a href="https://hackademy-30.gitlab.io/directory-donato-carrassi/">
                    Phone directory
                  </a>
                </h4>
                <p className={classes.description}>
                  Sample app that could be implemented into any website.
                </p>
              </CardBody>
            </Card>
          </GridItem>
        </GridContainer>
      </div>
    </div>
  );
}
